import React from 'react'
import LayoutStatic from '../../../components/LayoutStatic';

const IndexPage = () => {
  return (
    <LayoutStatic>
      <div className="card" style={{width: '100%', margin: 'auto', marginTop: '20px'}} >
          <img src="/img/TelePharmacy/TelePharHome.png" className="card-img-top" alt="..." />

          {/* <div className="card-body">
          <p className="card-text">มาตรการรักษาความปลอดภัย</p>
          <p className="card-text">การแพร่ระบาดของ Covid-19</p>
          <p className="card-text">ผู้ที่จะเข้ารับบริการ</p>
          <p className="card-text">ต้องทำ การคัดกรองโรค ก่อนทุกครั้ง</p> 

           <p className="card-text" style={{ textAlign:"center" }}>มาตรการรักษาความปลอดภัย
           การป้องกันการแพร่ระบาดของ Covid-19</p>
          <p className="card-text" style={{ textAlign:"center" , color: "red"}}>ผู้เข้ารับบริการต้อง 
          "คัดกรองความเสี่ยง" ก่อนทุกครั้ง</p> 

          <Link to="/menu/infant" className="btn btn-primary" style={style.button}>0-11 เดือน</Link>
          <Link to="/menu/child/01" className="btn btn-primary" style={style.button}>อายุ 1 ปี</Link>
          <Link to="/menu/child/02" className="btn btn-primary" style={style.button}>อายุ 2 ปี</Link>
          <Link to="/menu/child/03" className="btn btn-primary" style={style.button}>อายุ 3 ปี</Link>
          <Link to="/menu/child/04" className="btn btn-primary" style={style.button}>อายุ 4 ปี</Link>
          <Link to="/menu/child/05" className="btn btn-primary" style={style.button}>อายุ 5 ปี</Link>
          <Link to="/menu/child/06" className="btn btn-primary" style={style.button}>อายุ 6 ปี</Link>
          <Link to="/menu/child/07" className="btn btn-primary" style={style.button}>อายุ 7 ปี</Link>
          <Link to="/menu/child/08" className="btn btn-primary" style={style.button}>อายุ 8 ปี</Link> 

          </div> */}

          <div >
            
            <div style={{width: '100%'}} >
              <a href="https://liff.line.me/1654027181-KlRj2MDr">
              <img className="card-img-top" alt="..." src="/img/TelePharmacy/ButtonConsult.png"/>
              </a>
            </div>
            <div style={{width: '100%', margin: 'auto'}} >
              <a href="https://liff.line.me/1654027181-wA8R71kg">
              <img className="card-img-top" alt="..." src="/img/TelePharmacy/ButtonProduct.png"/>
              </a>
            </div>
          </div>
          
          
          <div style={{ padding:"10px"}}>
            <p></p>
            <p style={{ textAlign:"center" , color:"red"}} >ข้อกฏหมายที่เกี่ยวข้อง</p>
            <p style={{ textAlign:"center" }} >
              <a href="https://www.pharmacycouncil.org/index.php?option=content_detail&menuid=68&itemid=1846&catid=0">
                <u>*ประกาศสภาเภสัชกรรม ที่ 56/2563 เรื่อง การกำหนดมาตรฐานและขั้นตอนการให้บริการเภสัชกรรมทางไกล (Telepharmacy)</u>
                </a></p>

            <p style={{ textAlign:"center" }} >
              <a href="https://tmc.or.th/index.php/News/News-and-Activities/Telemedicine">
                <u>*ประกาศแพทยสภา ที่ 54/2563 เรื่อง แนวทางปฏิบัติการแพทย์ทางไกลหรือโทรเวช (telemedicine) และคลินิกออนไลน์</u>
                </a></p>
                
            <p style={{ textAlign:"center" }} ><a href="https://www.google.com"><u>*เงื่อนไขการใช้บริการ</u></a></p>
            <p style={{ textAlign:"center" , color:"red"}} ><a href="tel:+66944969653">ติดต่อเจ้าหน้าที่</a></p>
          </div>


      </div>
    </LayoutStatic>
  );
}

export default IndexPage

